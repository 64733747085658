/* eslint-disable no-undef */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
/* eslint-disable-next-line @typescript-eslint/no-var-requires */
require("@rails/activestorage").start();
require("../channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import React from 'react'
// import ReactDOM from 'react-dom'
// import App from './App';

// ReactDOM.render(<App />, document.getElementById('transactions'));

// export function mount(Component, mountNodeId) {
// document.addEventListener('DOMContentLoaded', () => {
// const mountNode = document.getElementById(mountNodeId);
// const propsJSON = mountNode.getAttribute('data-react-props');
// const props = JSON.parse(propsJSON);

// ReactDOM.render(<Component />, mountNode);
// })
// }
